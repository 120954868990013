export default async function sendConfirmationMail(order, backend_adress) {
  var response;

  var baseURL = backend_adress + "/";
  var url = baseURL + "sendConfirmationMail";

  console.log("order in confirmation mail: ");
  console.log(order);
  console.log(JSON.stringify(order));

  var output = "";

  await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      from: '"Tågluffa i Europa" kundservice@tagluffaieuropa.se',
      email: order.email,
      subject: "Bokningsbekräftelse interrailkort",
      messageHtml: confirmationMail(
        order.first_name,
        order.eurailOrderNumber,
        order
      ), // HTML code
    }),
  })
    .then(function (response) {
      if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
      }
      return response.json();
    })
    .then(function (json) {
      if (json) {
        response = json;
        console.log(response);
        if (response.msg == "fail") {
          throw new Error("Error when sending confirmation mail.");
        } else if (response.msg == "success") {
          console.log("Successfully sent confirmation mail!");
          output = "success";
        }
      }
    })
    .catch(function (error) {
      console.log("Confirmation mail failure: ", error);
      output = "fail";
    });

  return output;
}

function confirmationMail(name, ordernummer, order) {
  const translation = {
    CHD: "Barn",
    YTH: "Ungdom",
    ADT: "Vuxen",
    SEN: "Pensionär",
    "2ndclass": "Andra klass",
    "1stclass": "Första klass",
    SE: "Sverige",
    NO: "Norge",
    DK: "Danmark",
    FI: "Finland",
  };

  var totaltprice = 0;
  order.order.forEach((ticket) => (totaltprice += Number(ticket.price)));

  console.log("****");
  console.log(order);

  var activationDate = new Date(order.eurailBooking.createdAt);
  activationDate.setMonth(activationDate.getMonth() + 12);
  var lastActivationDate =
    activationDate.getFullYear() +
    "-" +
    ("0" + activationDate.getMonth()).slice(-2) +
    "-" +
    ("0" + activationDate.getDate()).slice(-2);

  const emailBody = `
  <html>
  <div style="width: 100%; min-height: 100vh; background-color: #F5F8FA;">
  <div style="max-width: 640px; margin-left:auto; margin-right:auto;">
    <!-- Header -->
    <table width="100%" bgcolor="#F5F8FA">
        <tr>
            <td align="center">
                <img src="https://tagluffaieuropa.se/pictures_for_external_use/tågluffaieuropa_logo.png" alt="Logo" width="280">
            </td>
        </tr>
    </table>
<div style="height: 1rem"></div>
<!-- Body -->
<div style="background-color: #FFFFFF;padding: 2rem">
    <table width="100%">
        <tr>
            <td>
                <h2>Tack för din bokning!</h2>
            </td>
            <td>
                <p>Ordernummer:<br />${ordernummer}</p>
            </td>
        </tr>
    </table>
    <table width="100%">
        <tr>
            <td>
                <p>Hej ${name}, vi är glada över att du bokat din resa genom oss! Du är fortfarande några steg bort från att gå ombord på ditt första tåg, så låt oss göra dina biljetter redo för resan.</p>
                <p>Varje pass har en tillhörande aktiveringskod
                du ska använda för att få fram varje biljett i Railplanner-appen
                .</p>
                
            </td>
            </tr>
    </table>
    <table width="100%">
      <tr>
          <td colspan="2">
              Här är dina passdetaljer:
          </td>
      </tr>
    ${order.order
      .map(
        (biljett) =>
          `
        <tr>
        <td style="width: 50%; vertical-align: top; padding-top: 1em;">
            Passagerarens namn:<br />
            <b>${biljett.firstName} ${biljett.lastName}</b><br />
            
            Passtyp:<br />
            <b>Interrail Global Pass</b><br />
            
            Biljettklass:<br />
            <b>${translation[biljett.comfort]}, ${
            translation[biljett.age]
          }</b><br />
            
            Boendeland:<br />
            <b>${
              translation[biljett.country]
            }</b> (Endast en ut- och inresa i detta land)
        </td>
        <td style="width: 50%; vertical-align: top; padding-top: 1em;">
            Mobile Pass nummer:<br />
            <b>${biljett.mobileActivationCode}</b><br />
            
            Varaktighet pass:<br />
            <b>${biljett.title}</b><br />
            
            ${
              /*Platsbokningsnummer:<br />
    <b>${biljett.get_name()}</b><br />*/ ""
            }
        
            Aktiveras senast:<br />
            <b>${lastActivationDate}</b>
        </td>
    </tr>
    `
      )
      .join("")}
</table>
 <table width="100%">
    <tr>
        <td colspan="2" style="text-align: center; padding-top: 1em; border-top: solid #F5F8FA">
            Sammanfattning
        </td>
    </tr>
    <tr><td><b>Pass</b></td><td style="text-align: right"><b>Pris</b></td></tr>
    ${order.order
      .map(
        (biljett) =>
          `
        <tr>
        <td style="padding-top: 0.5em">
            Interrail Global Pass, 
            ${biljett.type} <br />
			${
        "(" +
        translation[biljett.comfort] +
        ", " +
        translation[biljett.age] +
        ") "
      } <br />
				${biljett.firstName + " " + biljett.lastName}
        </td>
        <td style="padding-top: 0.5em; text-align: right;">
            ${biljett.price + ":-"}
        </td>
    </tr>
    `
      )
      .join("")}
    <tr><td><b>Totalt</b></td><td style="text-align: right;"><b>${
      totaltprice + ":-"
    }</b></td></tr>
</table>
                <table width="100%">
                  <tr>
                      <td colspan="2"  style="text-align: center">
                          <p>Ladda ner Rail Planner appen för att komma igång</p>
                      </td>
                  </tr>
                  <tr>
                      <td style="width: 50%; text-align: center; padding-bottom: 0.5em;">
                          <a href="https://apps.apple.com/se/app/eurail-interrail-rail-planner/id579547877">
                          <img src="https://tagluffaieuropa.se/pictures_for_external_use/AppStore.png" alt="App Store link" width="140"></a>
                      </td>
                      <td style="width: 50%; text-align: center; padding-bottom: 0.5em;">
                          <a href="https://play.google.com/store/apps/details?id=org.eurail.railplanner&pcampaignid=web_share">
                          <img src="https://tagluffaieuropa.se/pictures_for_external_use/GooglePlay.png" alt="Google Play link" width="140"></a>
                      </td>
                  </tr>
                  </table>
                  <table width="100%">
                    <tr style="background-color: #F5F8FA;">
                        <td style="padding: 1em;">
                            <h3 style="margin-block-start: 0px; margin-block-end:0px">Läs vår guide för att komma igång</h3>
                            <p>Innan du ger dig iväg, läs vår guide för att komma igång med ditt mobila pass. Vi visar hur du aktiverar ditt mobila pass i tre enkla steg.</p>
                            <a href="https://tagluffaieuropa.se/guider/mobile-pass" style="text-decoration: None; color: inherit;font-weight: bold;">Läs guiden</a>
                        </td>
                    </tr>
                </table>
                <table style="width: 100%">
                    <tr>
                        <td>
                            <h3 style="margin-bottom: 0px;">Har du en fråga?</h3>
                        </td>
                    <tr>
                        <td>
                            Se efter i våran FAQ
                        </td>
                        <td style="text-align: right;width: 30%">
                            <a href="https://tagluffaieuropa.se/support" style="text-decoration: None; color: inherit;font-weight: bold;">Kolla FAQ</a>
                        </td>
                    </tr>
                    </table>
                
                  <table width="100%">
                  <tr>
                      <td>
                          <br />
                          <p>Trevlig resa från oss på,</p>
                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p>Tågluffa i Europa</p>
                      </td>
                  </tr>
                </table><table style="width: 100%">
                <tr><td colspan="2" style="width: 50%; text-align: center;"><a href="https://se.trustpilot.com/review/tagluffaieuropa.se" style="text-decoration: none; color: inherit;"><div style="border: 1px solid #00b67a; padding: 8px; margin-top: 16px; border-radius: 10px; display: inline-block;">
            Lämna ett omdöme om oss på
            
                <img src="https://tagluffaieuropa.se/pictures_for_external_use/Trustpilot-Logo.png"
                    alt="Trustpilot logo" height="16">
            
        </div></a></td></tr>
            </table>
                
</div>
    <!-- Footer -->
    <table style="width: 100%; background-color:#F5F8FA; padding-top:1rem">
        <tr>
            <td align="center"; padding-bottom: 1em;>
                <p>Tack för att du använder dig av oss.</p>
                <p>&copy; 2023 Tågluffa i Europa</p>
                <p>I samarbete med</p>
                <img src="https://tagluffaieuropa.se/pictures_for_external_use/INTER-LOGO-blue-RGB.png" alt="Interrail logo" width="140">
            </td>
        </tr>
    </table>
    </div>
    </div>
    </html>
`;
  return emailBody;
}
