import HomePage from "@/components/views/HomePage.vue";
import InterrailCards from "@/components/views/InterrailCards.vue";
import SupportView from "@/components/views/SupportView.vue";
import TickeetPresentationView from "@/components/views/CardBuy/TicketPresentationView.vue";
import CustomerInfoView from "@/components/views/CardBuy/CustomerInfoView.vue";
import SummaryView from "@/components/views/CardBuy/SummaryView.vue";
import CardBuy from "@/components/views/CardBuy.vue";
import GuideView from "@/components/views/GuideView.vue";
import GuidesPage from "@/components/views/GuidesPage.vue";
import ArticlesPage from "@/components/views/ArticlesPage.vue";
import ArticlesView from "@/components/views/ArticlesView.vue";
import TravelForBeginners from "@/components/views/guides/TravelForBeginners.vue";
import MobilePass from "@/components/views/guides/MobilePass.vue";
import PassChoice from "@/components/views/guides/PassChoice.vue";
import PackingGuide from "@/components/views/guides/PackingGuide.vue";
import TicketChoiceGuide from "@/components/views/guides/TicketChoiceGuide.vue";
import TravelRulesGuide from "@/components/views/guides/TravelRulesGuide.vue";
import DisabilityGuide from "@/components/views/articles/DisabilityGuide.vue";
import ParisGuide from "@/components/views/guides/ParisGuide.vue";
import CinqueTerreGuide from "@/components/views/guides/CinqueTerreGuide.vue";
import PragueGuide from "@/components/views/guides/PragueGuide.vue";
import ConfirmationView from "@/components/views/ConfirmationView.vue";
import { createRouter, createWebHistory } from "vue-router";
import { useBookingStore } from "@/store/bookingStore";
import { useTrackingStore } from "@/store/trackingStore";
import { useAdminStore } from "@/store/adminStore";
import ReturnView from "@/components/views/ReturnView.vue";
import TermsAndConditionsView from "@/components/views/TermsView.vue";
import LoginView from "@/components/views/LoginView.vue";

const routes = [
  { path: "/", component: HomePage, name: "Home" },
  { path: "/login", component: LoginView, name: "Admin" },
  { path: "/interrailkort", component: InterrailCards, name: "Interrailkort" },
  { path: "/support", component: SupportView, name: "Support" },
  {
    path: "/confirmation",
    component: ConfirmationView,
    name: "Confirmation",
    beforeEnter: (to: any, from: any, next: any) => {
      const bookingStore = useBookingStore();
      if (bookingStore.order.eurailOrderNumber == "") {
        router.push("/");
      } else {
        next();
      }
    },
  },
  { path: "/return", component: ReturnView, name: "Return" },
  {
    path: "/vilkor",
    component: TermsAndConditionsView,
    name: "Allmänna vilkor",
  },
  {
    path: "/artiklar",
    component: ArticlesPage,
    children: [
      {
        path: "",
        component: ArticlesView,
        name: "Artiklar",
      },
      {
        path: "funktionsvariation",
        component: DisabilityGuide,
        name: "Artikel: Resa med en funktionsvariation",
      },
    ],
  },
  {
    path: "/guider",
    component: GuidesPage,
    children: [
      {
        path: "",
        component: GuideView,
        name: "Guider",
      },
      {
        path: "tagluffning-for-nyborjare",
        component: TravelForBeginners,
        name: "Guide: Tågluffning för nybörjare",
      },
      {
        path: "mobile-pass",
        component: MobilePass,
        name: "Guide: Mobile Pass",
      },
      {
        path: "packing-guide",
        component: PackingGuide,
        name: "Guide: Packning",
      },
      {
        path: "valj-interrailkort",
        component: TicketChoiceGuide,
        name: "Guide: Välj interrailkort",
      },
      {
        path: "med-pa-resan",
        component: TravelRulesGuide,
        name: "Guide: Det får du ha med på din Tågluff i Europa",
      },
      {
        path: "paris-frankrike",
        component: ParisGuide,
        name: "Guide: Paris",
      },
      {
        path: "cinque-terre",
        component: CinqueTerreGuide,
        name: "Guide: Cinque Terre",
      },
      {
        path: "prag-tjeckien",
        component: PragueGuide,
        name: "Guide: Prag",
      },
    ],
  },
  {
    name: "cardbuy",
    path: "/biljetter",
    component: CardBuy,
    beforeEnter: (to: any, from: any, next: any) => {
      const bookingStore = useBookingStore();
      bookingStore.get_tickets_from_db();
      next();
    },
    children: [
      {
        path: "biljettval",
        component: TickeetPresentationView,
        name: "Biljetter",
      },
      {
        path: "resenarer",
        component: CustomerInfoView,
        name: "Resenärer",
        beforeEnter: (to: any, from: any, next: any) => {
          const bookingStore = useBookingStore();
          if (bookingStore.shoppingCart.length <= 0) {
            router.push("/biljetter");
          } else {
            const trackingStore = useTrackingStore();
            trackingStore.trackEvent("begin_checkout");
            next();
          }
        },
      },
      {
        path: "summary",
        component: SummaryView,
        name: "Sammanfattning",
        beforeEnter: (to: any, from: any, next: any) => {
          const bookingStore = useBookingStore();
          if (bookingStore.shoppingCart.length <= 0) {
            router.push("/biljetter");
          } else {
            const trackingStore = useTrackingStore();
            trackingStore.trackEvent("continue_checkout");
            next();
          }
        },
      },
    ],
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach((to: any, from: any, next: any) => {
  // Get the page title from the route's 'name' attribute (if it exists)
  console.log(process.env);
  const adminStore = useAdminStore();
  console.log(adminStore.adminKey);
  if (
    (adminStore.adminKey == "" || adminStore.adminKey == null) &&
    to.fullPath != "/login"
  ) {
    router.push("/login");
    next();
  }

  if (typeof to.name === "string") {
    const title = to.name;
    // If the route has a title, set it as the page title of the document/page
    if (title) {
      document.title = title;
    }
  }

  if (to.fullPath == "/biljetter") {
    router.push("/biljetter/biljettval");
  } else {
    next();
  }
});
