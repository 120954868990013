import { defineStore } from "pinia";
import { router } from "@/router/router";
import generateinterrailCart from "../functions/generateinterrailCart";
import postEurailAPI from "../functions/postEurailAPI";
import sendConfirmationMail from "../functions/sendConfirmationMail";
import priceCheckerEurail from "../functions/priceCheckerEurail";
import ticket from "@/types/ticket";
import { useTrackingStore } from "@/store/trackingStore";

const nodeFetch = require("node-fetch");

export type order = {
  firstname: string;
  surname: string;
  adress: string;
  city: string;
  postalNumber: string;
  phoneNumber: string;
  email: string;
  swishFirstname: string;
  swishSurname: string;
  swishPhoneNumber: string;
  acceptTermsAndConditions: boolean;
  originalPaymentReference: string;
  bookStatus: string;
  CurrentSwishIdentifier: string;
  shoppingCartId: string;
  eurailOrderNumber: string;
  eurailOrderId: string;
  tagluffaOrderNumber: string;
  eurailBooking: any;
  orderStatus: string;
};

export type PriceObj = {
  age: string;
  comfort: string;
  price: 0;
  sku: string;
};

export type TicketReference = {
  id: string;
  title: string;
  price_object: PriceObj[];
};

export type Booking = {
  order: order;
  shoppingCart: ticket[];
  ticketBuyPaths: {
    path: string;
    title: string;
    valid: boolean;
    nextPath: string;
  }[];
  availableTickets: TicketReference[];
};

const backend_adress = process.env.VUE_APP_BACKEND_ADRESS;

export const useBookingStore = defineStore({
  id: "bookingStore",
  state: () =>
    ({
      order: {
        firstname: "Klas",
        surname: "Klasson",
        adress: "Klasgatan 4",
        city: "Klasistan",
        postalNumber: "12345",
        phoneNumber: "0705777903",
        swishPhoneNumber: "0705777903",
        email: "jens.hulteberg@tagluffaieuropa.se",
        swishFirstname: "Klas",
        swishSurname: "Kalsson",
        acceptTermsAndConditions: false,
        originalPaymentReference: "",
        CurrentSwishIdentifier: "",
        bookStatus: "",
        shoppingCartId: "",
        eurailOrderNumber: "",
        eurailOrderId: "",
        orderStatus: "",
        tagluffaOrderNumber: "",
        eurailBooking: {},
      },

      shoppingCart:
        process.env.NODE_ENV == "development"
          ? [
              {
                id: "3_7 dagar på 1 månad",
                title: "7 dagar på 1 månad",
                type: "ticket",
                sku: "30405000000211",
                firstName: "Lars",
                lastName: "Larsson",
                age: "ADT",
                comfort: "second",
                country: "SE",
                dateOfBirth: "1993-08-04",
                price: 6200,
                VAT: 100,
                refundable: false,
              },
              {
                id: "3_7 dagar på 1 månad",
                title: "7 dagar på 1 månad",
                type: "ticket",
                sku: "30405000000211",
                firstName: "Klas",
                lastName: "Klasson",
                age: "ADT",
                comfort: "second",
                country: "SE",
                dateOfBirth: "1993-08-04",
                price: 4499,
                VAT: 100,
                refundable: false,
              },
            ]
          : [],
      ticketBuyPaths: [
        {
          path: "biljettval",
          title: "Produkt",
          valid: true,
          nextPath: "resenarer",
        },
        {
          path: "resenarer",
          title: "Resenärer",
          valid: false,
          nextPath: "summary",
        },
        {
          path: "summary",
          title: "Summering",
          valid: false,
          nextPath: "",
        },
      ],
      availableTickets: [] as TicketReference[],
    } as Booking),
  getters: {
    itemsInShoppingCart: (state) => state.shoppingCart.length,
    currentBuyPath: (state) => {
      const current_path_object = state.ticketBuyPaths.find(
        (el) => "/biljetter/" + el.path == router.currentRoute.value.path
      );
      return current_path_object;
    },
  },
  actions: {
    add_to_shopping_cart(ticket: ticket) {
      this.$state.shoppingCart.push(ticket);
      const trackingStore = useTrackingStore();
      trackingStore.trackEvent(
        "add_to_cart",
        trackingStore.generateGA4EventParametersItem(ticket)
      );
      this.validate_step("tickets");
    },

    totalVAT(ticketList: Array<ticket>) {
      let total = 0;
      ticketList.forEach((ticket: ticket) => {
        // Check status of ticket from booking
        const status = ticket.status;

        // Check if the status is neither "refunded" nor "reimbursed"
        if (status !== "refunded" && status !== "reimbursed") {
          total += ticket.VAT;
        }
      });
      return total;
    },

    totalPrice(ticketList: Array<ticket>) {
      let total = 0;
      ticketList.forEach((ticket: ticket) => {
        const status = ticket.status;

        // Check if the status is neither "refunded" nor "reimbursed"
        if (status !== "refunded" && status !== "reimbursed") {
          total += ticket.price;
        }
      });
      return total;
    },

    validate_stuff_in_shopping_cart() {
      return this.$state.shoppingCart.length > 0;
    },

    get_step_by_name(step_name: string) {
      const current_path_object = this.$state.ticketBuyPaths.find(
        (el) => el.path == step_name
      );


      return current_path_object;
    },

    validate_step(step_name: string) {
      const step_obj = this.get_step_by_name(step_name);

      if (!step_obj) {
        return;
      }
      if (step_obj?.path == "tickets") {
        step_obj.valid = this.$state.shoppingCart.length > 0;
      }

      if (step_obj?.path == "resenarer") {
        step_obj.valid = this.validateTicketsInCart(this.shoppingCart);
      }
    },

    update_ticket_in_shoppingcard(
      index: number,
      changes: {
        age?: string;
        comfort?: string;
        firstName?: string;
        lastName?: string;
        country?: string;
        dateOfBirth?: string;
      }
    ) {
      const ticket_to_change = this.shoppingCart[index];
      if (changes.age != null) {
        ticket_to_change.age = changes.age;
      }
      if (changes.comfort != null) {
        ticket_to_change.comfort = changes.comfort;
      }
      if (changes.firstName != null) {
        ticket_to_change.firstName = changes.firstName;
      }
      if (changes.lastName != null) {
        ticket_to_change.lastName = changes.lastName;
      }
      if (changes.country != null) {
        ticket_to_change.country = changes.country;
      }
      if (changes.dateOfBirth != null) {
        ticket_to_change.dateOfBirth = changes.dateOfBirth;
      }
      this.validate_step("resenarer");
    },

    validateTicketsInCart(shoppingCart: ticket[]) {
      let shopping_cart_valid = true;
      for (let i = 0; i < shoppingCart.length; i++) {
        const ticket = shoppingCart[i];

        const do_not_validate_these_keys = ["refundable"];

        const ticket_valid = Object.keys(ticket).every((key) => {
          if (do_not_validate_these_keys.includes(key)) {
            return true;
          }
          if (
            ticket[key as keyof ticket] &&
            ticket[key as keyof ticket] != null &&
            ticket[key as keyof ticket] != ""
          ) {
            if (this.is_age_valid_for_ticket(i, ticket.dateOfBirth)) {
              return true;
            } else {
              console.log("Ticket does not have valid age", ticket);
              return false;
            }
          } else {
            console.log("Ticket is missing field", ticket, key);
            return false;
          }
        });

        if (!ticket_valid) {
          shopping_cart_valid = false;
          break;
        }
      }

      return shopping_cart_valid;
    },

    get_ticket_by_id(id: string) {
      const found = this.availableTickets.find((ticket) => ticket.id == id);

      if (found) {
        return found;
      } else {
        return {} as {
          id: string;
          title: string;
          price_object: { age: string; comfort: string; price: 0 }[];
        };
      }
    },

    is_age_valid_for_ticket(index: number, date_of_birth: string) {
      const ticket_to_check = this.shoppingCart[index];

      const diff_ms = Date.now() - Date.parse(date_of_birth);
      const age_dateTime = new Date(diff_ms);
      const age = Math.abs(age_dateTime.getUTCFullYear() - 1970);

      if (ticket_to_check.age == "CHD") {
        return age < 12;
      }

      if (ticket_to_check.age == "YTH") {
        return age >= 12 && age < 28;
      }

      if (ticket_to_check.age == "ADT") {
        return age >= 28 && age < 60;
      }

      if (ticket_to_check.age == "SEN") {
        return age >= 60;
      }

      return false;
    },

    summarize_price() {
      let sum = 0;
      this.shoppingCart.forEach((ticket) => {
        sum += Number(ticket.price);
      });

      return sum + "";
    },

    get_tickets_from_db() {
      fetch(backend_adress + "/alltickets")
        .then((resp) => resp.json())
        .then((data) => {
          const ticket_list = data.ans;

          const output: TicketReference[] = [];

          ticket_list.forEach(
            (ticket_obj: {
              date: string;
              discount: number;
              duration: string;
              id: number;
              name: string;
              picPath: string;
              price: string;
              promotion: number;
            }) => {
              const preppedPrice: PriceObj[] = [];
              const parsed_price_from_db = JSON.parse(ticket_obj.price);

              Object.keys(parsed_price_from_db).forEach((age_key: string) => {
                preppedPrice.push(
                  {
                    age: age_key,
                    comfort: "first",
                    price: parsed_price_from_db[age_key]["1stclass"].price,
                    sku: parsed_price_from_db[age_key]["1stclass"].sku,
                  },
                  {
                    age: age_key,
                    comfort: "second",
                    price: parsed_price_from_db[age_key]["2ndclass"].price,
                    sku: parsed_price_from_db[age_key]["2ndclass"].sku,
                  }
                );
              });

              output.push({
                id: ticket_obj.id + "_" + ticket_obj.name,
                title: ticket_obj.name,
                price_object: preppedPrice,
              });
            }
          );

          this.availableTickets = output;
        });
    },

    async bookEurail(shopping_cart: any, order: any) {
      console.log(shopping_cart);
      const myHeaders = new nodeFetch.Headers();
      myHeaders.append(
        "Authorization",
        "Bearer pHlCLuAeF4dlH9RpfpPdgSA5bhOI0vgCIgtShpRLXNU0JmIvfYb7PqWbpwkOjJiecZW7yPhrvphIrOP5"
      );
      myHeaders.append("Content-Type", "application/json");

      let interrailCart;
      const trackingStore = useTrackingStore();

      /*
      Booking response status messages:
      200 - OK
      400 - Unspecified error
      401 - 
      */

      const message = {
        status: 400,
        statusMessage: "Unspecified error",
        body: "",
        concatenatedError: [],
        error: "",
      };

      try {
        interrailCart = generateinterrailCart(shopping_cart, order);
      } catch (err: any | never) {
        message.error = err;
        message.concatenatedError.push(err as never);
        message.concatenatedError.push(
          "Error in interrail cart creation (generateinterrailCart). " as never
        );
        return message;
      }

      let price = 0;

      const result = this.createCartWithContent(interrailCart, message)
        .then((result: any) => {
          if (result.error) {
            result.concatenatedError.push("Error in cart creation at Eurail");
            throw result;
          }
          this.$state.order.shoppingCartId = result.body.id;
          console.log("price:", price);
          shopping_cart.map(
            (ticket: ticket) => (price += Number(ticket.price))
          );
          return priceCheckerEurail(result, shopping_cart, price);
        })
        .then((result) => {
          if (result.error) {
            result.concatenatedError.push("Verifying price failed.");
            throw result;
          }
          if (result.body == false) {
            result.error = "Eurail prices detected to be higher then ours.";
            result.concatenatedError.push(
              "Eurail prices detected to be higher then ours."
            );
            throw result;
          }
          console.log("id to validate:", this.$state.order.shoppingCartId);
          return this.validateCart(this.$state.order.shoppingCartId, result);
        })
        .then((result: any) => {
          if (result.error) {
            result.concatenatedError.push("Validating cart did not succeed.");
            throw result;
          }
          console.log("Tickets valid? ", result.body);

          // Send order to incomplete orders table to logg
          return this.send_process_to_db(order, shopping_cart, result);
        })
        .then((result) => {
          if (result.error) {
            result.concatenatedError.push(
              "Sending draft order to incomplete_orders did not succeed."
            );
            throw result;
          }
          // Send order information per mail to us

          this.swishDialogUpdate("swish_pending");

          // shopping_cart.map((ticket) => (price += ticket.get_price()))


          return this.prepareSwishPayment(
            1,
            "46" + order.swishPhoneNumber.substring(1),
            result
          );
        })
        .then((result) => {
          console.log("Prepare swish:");
          console.log(result);
          if (result.error) {
            // Överflödig
            result.concatenatedError.push(
              "Preparing swish payment did not succeed"
            );
            throw result;
          }
          return this.checkSwishStatus(result.body);
        })
        .then((result: any) => {
          if (!result) {
            // if (!result.body) {
            result = message;
            result.statusMessage = "Error";
            result.error = "Swish payment failed.";
            result.concatenatedError.push(result.error);
            throw result;
          }
          result = message;

          this.swishDialogUpdate("swish_paid");
          console.log(
            "creating order with id: ",
            this.$state.order.shoppingCartId
          );

          return this.createOrder(this.$state.order.shoppingCartId, result);
        })
        .then((result: any) => {
          if (!result) {
            // TODO: Send failed booking to failed booking database
            /*sendFailedBook(
              pnr_numbers,
              railBookRQ,
              railBookRS.ACP_RailBookRS.Errors[0].Error[0]
            );*/
            throw "Create order did not execute properly";
          }
          if (result.error) {
            result.concatenatedError.push(
              "Create order did not execute properly"
            );
            throw result;
          }
          this.add_activation_code_to_tickets(shopping_cart, result.body);
          this.$state.order.eurailOrderNumber = result.body.orderNumber; // This is the one of importance
          this.$state.order.eurailOrderId = result.body.id;
          this.$state.order.eurailBooking = result.body;

          // Send booking information to database & send confirmation mail
          return this.send_booking_to_db(order, shopping_cart, result);
        })
        .then(function (result) {
          // Make sure everything went fine and then return
          if (!result) {
            // TODO: Alert admin about error of dB storage and confirmation mail.
            throw "Sending confirmation mail or storing in dB was faulty.";
          }
          trackingStore.trackEvent(
            "purchase",
            trackingStore.generateGA4EventParametersPurchase(order)
          );

          return true;
        })
        .catch((error) => {
          console.log("Error: ", error);
          trackingStore.trackEvent("checkout_error", { error_message: error });
          return error;
          // (Store error order)
          // Return error for customer to be faced with
        });
      return result;
    },

    async createCartWithContent(cart: any, message: any) {
      message.body = cart;
      const result = await postEurailAPI(
        message,
        "create_cart",
        backend_adress
      );

      //console.log("Cart was created with id:", result.id);
      console.log("createCartResult:");
      console.log(result);

      return result;
    },

    async validateCart(cartId: any, message: any) {
      console.log("validation received id: ", cartId);
      message.body = cartId;
      const result = await postEurailAPI(
        message,
        "validate_cart",
        backend_adress
      );

      return result;
    },

    async send_process_to_db(order: any, cart: any, message: any) {
      let result = {} as any;
      order.order = cart;
      const url = backend_adress + "/booking2dB";
      result = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          status: false,
          eurail_order_number: "-",
          order: order,
          swish_status: "NOT_PAID",
          error_msg: "None",
          ACP_dump: message.body,
          shopping_cart: cart,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            message.status = response.status;
            throw response;
          }
          return response.json();
        })
        .catch(function (error) {
          if (message.status < 205) {
            message.status = 400;
          }
          message.error = error;
          message.concatenatedError.push(error);
          message.concatenatedError.push(
            "An error has occured in frontend communication with backend when storing draft booking in incomplete orders database."
          );
          return message;
        });
      return message; //Currently not happening nothing with this error
    },

    swishDialogUpdate(update: string) {
      this.$state.order.bookStatus = update;
    },

    async prepareSwishPayment(amount: any, swish_number: any, result: any) {
      let swishIdentifier;

      console.log("Talking to swish!");
      console.log("pris:", amount);
      console.log("swish_number:", swish_number);
      console.log("result:", result);
      const baseURL = backend_adress + "/";

      const url = baseURL + "paymentrequests";
      const id = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          payerAlias: swish_number,
          amount: amount, // Pris på biljetter
          message: "Interrailkort",
          result: result,
          callbackUrl: "https://" + window.location.host + "/swishCallback",
        }),
      })
        .then(function (response) {
          console.log("return from swish:", response);
          if (!response) {
            //if (!response.ok) {
            result.status = response as never;
            throw response;
          }
          return response.json();
        })
        .then((json) => {
          console.log("Returning json:", json);
          if (json) {
            if (json.status != 201) {
              throw json;
            }
            this.$state.order.CurrentSwishIdentifier = json.body["id"];
            swishIdentifier = json.body["id"];
            console.log(
              "Payment request created with identifier " +
                json.body["id"] +
                ", open app."
            );

            //State change for visuals to check
            this.$state.order.bookStatus = "swish_pending";

            console.log("End of swish!");
            console.log("Leaving with:", result);
            result.body = swishIdentifier;
            result.statusMessage = "Successfully sent swish request";
            return result;
          }
        })
        .catch(function (error) {
          console.log("Request failure: ", error);
          error.concatenatedError.push(
            "Preparing swish payment did not succeed"
          );
          console.log("Return from swish!");
          throw error;
        });
      return id;
    },

    checkSwishStatus(result: any) {
      //var swish_id = result.body;
      // result.body =
      return new Promise((resolve, reject) => {
        const swishCheck = window.setInterval(async () => {
          const swish_status = await this.checkActiveSwish(result); //swish_id);
          if (swish_status) {
            clearInterval(swishCheck);
            resolve(swish_status);
          } else if (swish_status == false) {
            clearInterval(swishCheck);
            resolve(swish_status);
          }
        }, 1000);
        setTimeout(() => {
          clearInterval(swishCheck);
          resolve(false);
        }, 60000);
      });
    },

    async createOrder(cartId: any, message: any) {
      console.log("validation received id: ", cartId);
      message.body = {
        id: cartId,
        version: 1,
        paymentInterface: "nonepayment",
      };

      const result: any = await postEurailAPI(
        message,
        "create_order",
        backend_adress
      );
      if (result.error) {
        result.concatenatedError.push("Create order did not execute properly");
        throw result;
      }
      if (!Object.prototype.hasOwnProperty.call(result.body, "orderNumber")) {
        result.concatenatedError.push("Create order did not execute properly");
        throw result;
      }
      return result;
    },

    add_activation_code_to_tickets(shopping_cart: any, eurail_order: any) {
      // Index in booking will be same as index in shopping cart
      let index = 0;
      shopping_cart.map((ticket: any) => {
        if (ticket.type === "ticket") {
          ticket.mobileActivationCode =
            eurail_order.lineItems[index].custom.fields.mobilePassNumber;
          ticket.eurailTicketId = eurail_order.lineItems[index].id;
          index += 1;
        }
      });
    },

    async send_booking_to_db(order: any, cart: any, message: any) {
      console.log("___");
      console.log(order);
      console.log(cart);
      console.log(message);
      console.log("___");
      let result = {} as any;
      order.order = cart;
      this.$state.order.orderStatus = "purchased";
      const url = backend_adress + "/booking2dB";
      result = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          status: true,
          eurail_order_number: message.body.orderNumber,
          order: order,
          ACP_dump: message.body,
          shopping_cart: cart,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            message.status = response.status;
            throw response;
          }
          return response.json();
        })
        .then((response) => {
          this.$state.order.tagluffaOrderNumber = response.ans;
          /*
          var temp_order = props.order;
          temp_order.tagluffa_order_number = response.ans;
          temp_order.order = props.shopping_cart;
          props.update_order(temp_order);
          */
        })
        .then(() => {
          console.log("About to sent confirmation mail.");
          console.log(order);
          sendConfirmationMail(order, backend_adress).then((response) => {
            if (response == "success") {
              console.log("Confirmation mail successfully sent.");
            } else {
              console.log("Failed to send confirmation mail.");
              throw "Failed to send confirmation mail.";
            }
          });
          return true;
        })
        .catch(function (error) {
          if (message.status < 205) {
            message.status = 400;
          }
          message.error = error;
          message.concatenatedError.push(error);
          message.concatenatedError.push(
            "An error has occured in frontend communication with backend when storing booking in database."
          );
          return message;
        });
      return result;
    },

    async checkActiveSwish(SwishIdentifier: any) {
      let status;
      const baseURL = backend_adress + "/";
      const url = baseURL + "paymentrequests/" + SwishIdentifier;
      const error = "";

      await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then((json) => {
          if (json.status == "PAID") {
            this.$state.order.originalPaymentReference =
              json["paymentReference"];
            console.log("Swish was paid correctly!");
            // Close dialog
            // Send user to confirmation page
            //          bookACPTicket().then((result) =>
            //            result
            //              ? history.push("/biljetter/sendof")
            //              : console.log("Tickets where not booked correctly!")
            //          );
            //history.push("/biljetter/sendof");
            status = true;
          } else if (json.status == "FAILED") {
            // TODO: Verify failure status
            // Alter error message for order to be stored in dB

            error.concat(" | ");

            error.concat("Swish payment failed.");
            // Show failure message
            status = false;
          } else if (json.status == "ERROR") {
            // TODO: Verify failure status
            // Alter error message for order to be stored in dB

            error.concat(" | ");

            error.concat("Swish payment encountered an error!");
            // Show failure message
            status = false;
          } else if (json.status == "DECLINED") {
            // TODO: Verify failure status
            // Alter error message for order to be stored in dB

            error.concat(" | ");

            error.concat("Swish payment was declined!");
            // Show failure message
            status = false;
          }
          // Send order and swish_status to dB (COMMENTED THIS PART AWAY DUE TO MISMATCH IN DB STRUCTURE PLEASE RESOLVE //JENS)
          /* url = baseURL + "order2dB";
          fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              swish_status: json.status,
              order: sanitizer(props.order),
              error: error,
            }),
          }); */
          console.log(
            "Payment(identifier: " +
              SwishIdentifier +
              ", paymentReference: " +
              this.$state.order.originalPaymentReference +
              ") " +
              json.status
          );
        })
        .catch(function (error) {
          console.log("Request failure: ", error);
        });
      return status;
    },

    is_order_invalid(order: order, shoppingCart: ticket[]) {
      console.log(order);
      console.log(shoppingCart);
      if (!order.firstname) {
        return "Förnamn saknas";
      }
      if (!order.surname) {
        return "Efternamn saknas";
      }
      if (!order.email) {
        return "Mailadress saknas";
      }
      if (!order.adress) {
        return "Adress saknas";
      }
      if (!order.postalNumber) {
        return "Postnummer saknas";
      }
      if (!order.city) {
        return "Ort saknas";
      }
      if (!order.phoneNumber) {
        return "Telefonnummer saknas";
      }
      if (!order.swishFirstname) {
        return "Förnamn till swishnummer saknas";
      }
      if (!order.swishSurname) {
        return "Efternamn till swishnummer saknas";
      }
      if (!order.swishPhoneNumber) {
        return "Swishnummer saknas";
      }
      if (!order.acceptTermsAndConditions) {
        return "Villkor har inte godkänts";
      }
      if (shoppingCart.length <= 0) {
        //FIX THIS ITS FOR DEBUG ONLY
        return "Order är tom";
      }
      if (!this.validateTicketsInCart(shoppingCart)) {
        return "Alla biljetter är inte godkända";
      } else {
        const passengers = {
          CHD: 0,
          YTH: 0,
          ADT: 0,
          SEN: 0,
        };
        shoppingCart.forEach((element: ticket) => {
          if (element.status != "refunded" && element.status != "reimbursed") {
            passengers[
              element.age as keyof {
                CHD: 0;
                YTH: 0;
                ADT: 0;
                SEN: 0;
              }
            ] =
              passengers[
                element.age as keyof {
                  CHD: 0;
                  YTH: 0;
                  ADT: 0;
                  SEN: 0;
                }
              ] + 1;
          }
        });
        if (passengers.CHD > 0) {
          if (passengers.ADT == 0) {
            return "För få vuxna sett till antalet barn";
          } else if (passengers.CHD / passengers.ADT > 2) {
            return "För få vuxna sett till antalet barn";
          }
        }
      }
      return false;
    },
  },
});
